@use '../../../common/css/base' as base;
@use '~ag-grid-community/styles' as ag;

[class*='ag-theme-'] {
  font-family: base.$font !important;
}

.img-no-shirnk {
  flex-shrink: 0;
}

.author-landlord-color {
  color: base.$ap-deep-blue !important;
}

.author-tenant-color {
  color: base.$ap-green !important;
}

.author-no-color {
  color: base.$ap-medium-dark-gray !important;
  font-style: italic !important;
  font-size: var(--custom-dashboard-author-font-size) !important;
}

.loi-status-font-weight-parent {
  font-weight: 700 !important;
}

.loi-status-font-weight-child {
  font-weight: 600 !important;
}

.status-active-color {
  color: base.$ap-dark-orange !important;
}

.status-in-lease-color {
  color: base.$ap-black !important;
}

.status-dead-deal-color {
  color: base.$ap-medium-gray !important;
}

.cell-in-lease {
  color: base.$ap-black !important;
}

.row-child-lease {
  background: var(--AP-header-gray, #dcdcdc);
}

// .row-child-lease {
//   &.pinned-column-cell-color div {
//     background: var(--AP-header-gray, #ffe6af) !important;
//   }
// }

.parent-row-expanded {
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.25);
  z-index: 10;
}
.neighbour-row-expanded {
  -webkit-box-shadow: 0px -4px 7px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px -4px 7px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px -4px 7px 0px rgba(0, 0, 0, 0.25);
  z-index: 10;
}

// .ag-pinned-left-cols-container .ag-cell:nth-child(2n-1),

// .ag-pinned-left-cols-container .ag-cell:first-child,
// .ag-pinned-right-cols-container .ag-cell:last-child {
//   justify-content: center !important;
// }

// .ag-header-cell:last-child .ag-header-cell-comp-wrapper div {
//   justify-content: center !important;
// }

.ag-root-wrapper {
  border: #040404;
}

.header-border-common {
  content: '';
  position: relative;
  display: inline-block;
  width: 100%;
  height: var(--custom-page-header-border-element-height) !important;
  z-index: 2;
}

.ag-pinned-left-header {
  [role='columnheader']::after {
    @extend .header-border-common;
    border-top: var(--custom-page-header-border-thickness) solid base.$ap-black;
    background-color: base.$ap-dark-orange !important;
  }
}

.ag-header-viewport {
  [role='columnheader']::after {
    @extend .header-border-common;
    border-top: var(--custom-page-header-border-thickness) solid base.$ap-black;
    background-color: base.$ap-black !important;
  }
}

.ag-pinned-right-header {
  [col-id='0']::after,
  [col-id='notes-hidden']::after,
  [col-id='Deal Notes and Next Steps']::after {
    @extend .header-border-common;
    border-top: var(--custom-page-header-border-thickness) solid base.$ap-black;
    background-color: base.$ap-dark-orange !important;
  }
  // [col-id='Deal Notes and Next Steps']::after {
  //   visibility: hidden;
  // }
}

.ag-header-row {
  [aria-colindex='27']::after {
    // background-color: #4e4d4d !important;
    // border-top: 0;
  }
}

[aria-colindex='3']::after {
  background-color: transparent !important;
}

.ag-header {
  background-color: #4e4d4d;
}

.ag-cell-wrapper {
  flex: 1;
}

.ag-keyboard-focus .ag-header-cell:focus::after {
  position: relative !important;
  width: 100% !important;
  top: auto !important;
  left: auto !important;
}

.ag-horizontal-left-spacer,
.ag-horizontal-right-spacer {
  display: inline-table !important;
}

.ag-header-cell {
  border-right: var(--custom-page-header-border-thickness) solid base.$ap-black;
  font-size: var(--custom-font-size) !important;
  padding: 0px !important;
}

.ag-pinned-right-header-with-scroll {
  margin-right: 1.25em;
}

@-moz-document url-prefix() {
  .ag-pinned-right-header-with-scroll {
    margin-right: 1.2em;
  }
}
@media screen and (max-height: 700px) {
  @-moz-document url-prefix() {
    .ag-pinned-right-header-with-scroll {
      margin-right: 0em;
    }
  }
}

.ag-cell {
  font-size: var(--custom-font-size) !important;
  line-height: var(--custom-line-height) !important;
  cursor: pointer;
}

.ag-header-cell-comp-wrapper {
  align-items: center !important;
  width: 100%;
}

.ag-cell-wrap-text {
  word-break: break-word !important;
}

.ag-pinned-left-header,
.ag-pinned-left-cols-container {
  box-shadow: 4px 0px 7px 0px rgba(0, 0, 0, 0.25);
  z-index: 10;
}

.ag-pinned-right-header,
.ag-pinned-right-cols-container {
  box-shadow: -4px 0px 7px 0px rgba(0, 0, 0, 0.25);
  z-index: 10;

  * > [aria-label='display component'],
  [aria-label='textarea component'] {
    height: inherit !important;
  }
}

.ag-cell-focus {
  border: solid 0.016rem #b8b8b8 !important;
}

.ag-header-viewport .ag-header-active {
  background-color: #dcdcdc !important;
}

.market-column {
  background-color: #f2f2f2;
}

.pinned-column-right-shadow {
  box-shadow: 4px 0px 7px 0px rgba(0, 0, 0, 0.25);
}

.setting-column-color {
  background-color: #4e4d4d !important;
  border-right: #4e4d4d !important;
}

.normal-column-color {
  flex-direction: column;
  background-color: #dcdcdc !important;
}

.pinned-column-cell-color {
  background-color: #fdf3dc;
}

.selected-row-color {
  background-color: #fad47f;
  * {
    color: base.$ap-black !important;
  }
}

.pinned-column-header {
  flex-direction: column;
  background-color: #ffb000 !important;
}

.ag-cell-font-common {
  display: flex;
  color: base.$ap-black;
  font-family: base.$font;
  font-style: normal;
  line-height: var(--custom-line-height);
  justify-content: flex-start !important;
}

.header-text-align {
  flex: 1 1 0% !important;
  justify-content: start !important;
  align-items: baseline !important;
}

.header-no-padding-margin {
  padding: 0px !important;
  margin: 0px !important;
}

.loi-status-font-color {
  display: flex;
  font-family: base.$font;
  font-style: normal;
  line-height: var(--custom-line-height);
  justify-content: flex-start !important;
  color: #2e2e2e !important;
}

.ag-sticky-top {
  background-color: #4e4d4d;
}

.ag-header {
  border-bottom: 0px !important;
}
.ag-theme-alpine {
  --ag-selected-row-background-color: transparent !important;
}

@media screen {
  .ag-theme-alpine {
    --ag-border-color: #404040;
    --ag-borders-critical-color: #404040;
    --ag-secondary-border-color: #404040;

    // row styling
    --ag-row-border-style: solid;
    --ag-row-border-width: 0.313px;
    --ag-row-border-color: #404040;
    // --ag-selected-row-background-color: #fad47f;

    --ag-header-column-separator-display: block;

    // cell stlying
    --ag-cell-horizontal-border: solid 0.313px #404040;
    // --ag-header-cell-hover-background-color: #dcdcdc;
  }
  .ag-body-vertical-scroll {
    padding: 0 16px;
    background: #4e4d4d;
    align-items: center;
    justify-content: center;
  }

  .ag-pinned-right-header-with-scroll {
    // margin-right: 1.27em;
  }

  .ag-body-horizontal-scroll {
    padding-top: 14px;
    padding-bottom: 14px;
    background-color: #4e4d4d;
    align-items: center !important;
  }

  .ag-body-horizontal-scroll-viewport {
    height: 14px !important;
    max-height: 14px !important;
    min-height: 14px !important;
  }

  .ag-cell {
    display: flex;
    flex-shrink: 0;
    justify-content: flex-start;
    gap: 10.672px;
    padding: var(--custom-cell-padding);
    text-overflow: clip;
  }

  .field-values-text {
    // font-size: 12.532px;
    font-weight: 400;
  }

  .author-values-text {
    // font-size: 12.532px;
    font-weight: 600;
  }

  .market-values-text {
    // font-size: 12.532px;
    font-weight: 400;
  }

  .header-text {
    color: base.$ap-black;
    // font-size: var(--custom-font-size, 12.532px);
    font-weight: 700;
    line-height: var(--custom-line-height);
    flex-direction: column;
    white-space: break-spaces !important;
  }

  .ag-header-cell-comp-wrapper {
    padding: 10px;
    align-items: center !important;
    width: 100%;
    flex: 1;
  }

  .ag-row > .ag-cell:last-child {
    // border-right-width: 0px !important;
  }

  .notes-values-text {
    // font-size: 12.532px;
    font-weight: 400;
    line-height: var(--custom-line-height);
    // align-items: center;
    gap: 5px !important;
    img {
      width: 18.665px;
      height: 17.199px;
      padding-top: 2px;
    }
  }
}

.lease-filter-holder {
  flex-direction: row;
  gap: 0.5rem;
}
.contexify_theme-menu {
  /* --contexify-menu-bgColor: #404040; */
  --contexify-zIndex: 9999;
  --contexify-menu-minWidth: 10%;
  --contexify-menu-padding: 0px;
  --contexify-menu-radius: 0px;
  --contexify-activeItem-radius: 0px;
  --contexify-xIndex: 666;
  --contexify-menu-bgColor: #404040;
  --contexify-separator-color: rgba(0, 0, 0, 0.2);
  --contexify-item-color: #ffffff;
  --contexify-activeItem-color: #404040;
  --contexify-activeItem-bgColor: #ffb000;
  --contexify-rightSlot-color: #6f6e77;
  --contexify-activeRightSlot-color: #fff;
  --contexify-arrow-color: #6f6e77;
  --contexify-activeArrow-color: #fff;

  overflow-y: auto;
  max-width: 60vw;
  max-height: 60vh;

  .contexify__item__content {
    font-family: base.$font;
    font-style: normal;
    font-weight: 200;
    font-size: 5px !important;
    line-height: var(--custom-settings-line-height);
    display: flex;
    align-items: center;

    /* AP White */

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
}

[col-id='Trade Area'] .text-read,
[col-id='Deal Notes and Next Steps'] .text-read {
  height: 100%;
  white-space: normal;
  margin-top: -6px;
  text-overflow: ellipsis;
  width: 100%;
  line-height: var(--custom-line-height);
  font-style: italic;
  padding: 4px 7px;
}

.selected-row-color:has(.ta-text-area) {
  background-color: #ffe6af;
}
.selected-row-color {
  &[col-id='Deal Notes and Next Steps'] {
    background-color: #ffe6af;
  }
}

[col-id='Deal Notes and Next Steps'] .text-unread {
  font-weight: normal !important;
  font-style: italic;
}

[col-id='Children'] {
  .ag-header-cell-comp-wrapper {
    padding: 20px 3px;
  }
}

.ta-text-area {
  background-color: #ffe6af;
  border: none;
  width: 94% !important;
  outline: none;
  resize: none;
  // position: absolute;
  // top: -5px;
  // left: 0;
  vertical-align: text-top;
  vertical-align: top;
  padding: 25px;
  white-space: normal;
  font-size: var(--custom-font-size);
  line-height: var(--custom-line-height);
}

.ag-row > .ag-cell:last-child {
  // border-right-width: 0px !important;
}

.ag-header-cell::after {
  background-color: transparent !important;
}

.editable-cell {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  .indicator {
    padding-top: 2px;
  }
}

.tc-dead-deal {
  color: #b8b8b8;
}

.tc-cell-in-lease {
  color: #777777;
}

.tc-active-lease {
  color: #404040;
}

.combined-status-col {
  display: flex;
  flex-direction: column;
  .status-label:first-child {
    margin-top: 2px !important;
  }
}

.selected-row-color .tc-active-lease .status-label {
  font-weight: 700;
  line-height: var(--custom-line-height);
  margin-top: calc(var(--custom-line-height) * var(--custom-font-size) / 8);
}

.status-collapse {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  div:first-child {
    display: flex;
    align-self: flex-start;
  }
  .img-container {
    display: flex;
    img {
      padding-top: 2px;
    }
  }
}

.ta-input {
  background: rgba(65, 65, 65, 0.3);
  vertical-align: text-top;
}

.ta-input-label {
  background: rgba(65, 65, 65, 0.15);
  text-wrap: wrap !important;
  display: flex !important;
  align-items: center !important;
}
