@use "../../../../common/css/base" as base;

.selected-tab {
  font-weight: 600 !important;
  background: var(--AP-medium-yellow, #FAD47F);
}

.tab-name-unread-count {
  display: flex;
  gap: 5px;
  align-items: center;
}

.tab-name {
  display: flex;
  align-items: center;
}

.tab-name:first-child div {
  max-width: 70%;
}

.unread-count {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-size: 10px;
  font-weight: 700;
  color: white;
}

.unread-count-selected {
  background-color: #e99210;
}

.unread-count-unselected {
  background-color: #777;
}

.tab-tooltip {
  font-family: base.$font;
  font-size: var(--custom-font-size);
}

.tab-editable-value {
  color: base.$ap-black;
  height: var(--custom-tab-height) !important;
  display: flex;
  align-items: center;
}
