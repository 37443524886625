.dialog-container {
  display: flex;
  flex-direction: column;
  width: 325px;
  height: 400px;
  position: absolute;
  top: 20%;
  left: calc(50% - 150px);
  padding: 15px;
  box-sizing: border-box;
  color: var(--AP-white, #fff);
  font-size: 12.743px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 170.313% */
  border: 0.5px solid #fff;
  background: var(--AP-black-gray, #404040);
  box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.25);
  .dc-input-radio {
    margin-right: 10px;
    padding-top: 4px;

    &:hover {
      transform: scale(1.15);
    }
  }
  &.tab-message {
    height: auto;
    width: 276px;
    padding: 5px;
    top: 40%;
    .dc-header {
      color: var(--AP-white, #fff);
      font-size: 11.743px;
      font-weight: 700;
      line-height: 20px;
      .dc-tab-message-text {
        span {
          color: #ffb000;
        }
      }
    }
    .dc-footer {
      margin-top: 0.9rem;
      margin-bottom: 0.5rem;
    }
  }
  &.tab-list {
    height: 260px;
    padding: 10px;
    .dc-header {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11.743px;
      font-weight: 700;
      line-height: 20px;
    }
    .dc-body {
      margin-left: 0.1rem;
      display: flex;
      align-items: center;
    }
    .dc-move-list-container {
      overflow-y: hidden;
      height: 130px;
    }
    .move-tab-list {
      width: 255px;
      height: 125px;
      margin-left: 0.5rem;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .dc-footer {
      margin-top: 1rem;
    }
  }
  .dc-header {
    margin: 0.5rem;
    margin-left: 1rem;
  }
  .dc-highlight {
    color: #ffb000;
  }
  .dc-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-left: 1.5rem;
    .dc-input {
      display: flex;
      align-items: center;
      input {
        margin-right: 10px;
        width: 15px;
      }

      label {
        padding-top: 3px;
      }
    }
  }
  .dc-move-list-container {
    background-color: white;
    width: 240px;
    height: 150px;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    box-sizing: border-box;
  }
  .move-tab-list {
    display: flex;
    flex-direction: column;
    color: #404040;
    box-sizing: border-box;
    .move-tab-list-item {
      display: flex;
      width: 212px;
      height: 22px;
      align-items: center;
      margin-bottom: 0.25rem;
      padding: 5px 0;
      padding-left: 0.25rem;
      font-size: 10px;
      font-weight: 700;
      line-height: normal;
      &.selected {
        background-color: #404040;
        color: #ffb000;
      }
      &:hover {
        background-color: #a3a3a3;
        color: #ffb000;
        cursor: pointer;
      }
    }
  }
  .dc-footer {
    display: flex;
    gap: 1rem;
    margin-top: 0.95rem;
    justify-content: center;
    .dc-btn {
      background-color: #ffb000;
      color: #404040;
      text-align: center;
      font-size: 10.275px;
      font-weight: 600;
      line-height: normal;
      width: 65px;
      height: 30px;
      border-radius: 3.523px;
      font-family: Poppins;
      &.dc-cancel {
        background-color: white;
      }
    }
  }
}
